import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { StickyContainer } from "react-sticky";
import {
  Container,
  Grid,
  Button,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../components/GeneralComponent/Loader";
import FooterComponent from "../../components/FooterComponent";
import PropertyMapView from "../../components/PropertyMapView";
import SearchBar from "./SearchBar";
import SearchResultsListings from "./SearchResultsListings";
import NotificationBox from "../../components/GeneralComponent/NotificationBox";
import { withRouter } from "../../_helpers";
import { generalService } from "../../_services";
import "./styles.scss";
import PropertyCardSkeleton from "../../components/PropertyCardSkeleton";
import { Skeleton } from "@material-ui/lab";

const SearchPage = ({ location, alert, clearAlerts }) => {
  const [isFooterOpen, setIsFooterOpen] = useState(false);
  const [list_mode, setListMode] = useState(true);
  const [initialDataLimit, setInitialDataLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [properties, setProperties] = useState({
    total: 0,
    data: [],
    max_property_value: 0,
    max_price_per_share: 0,
    max_dividend_yield: 0,
  });
  const [search, setSearch] = useState({});
  const [activePropertyLocations, setActivePropertyLocations] = useState({});
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const searchParams = new URLSearchParams(useLocation().search);

  useEffect(async () => {
    clearAlerts();

    let searchObj = {};
    for (const [key, value] of searchParams.entries()) {
      try {
        searchObj[key] = JSON.parse(value);
      } catch (e) {
        searchObj[key] = value;
      }
    }

    if (location.state?.activePropertyLocations) {
      // came from home search
      setActivePropertyLocations(location.state.activePropertyLocations);
      setSelectedCountries(searchObj.countries || []);
    } else {
      // on marketplace load locations from backend
      const actLocationsRes =
        await generalService.getAllPropertyActiveLocations();
      setActivePropertyLocations(
        (actLocationsRes?.status && actLocationsRes?.data) || {}
      );
    }

    if (searchParams.toString().length > 0) {
      updateFilters(searchObj);
    } else {
      updateFilters({});
    }
  }, []);

  useEffect(() => {
    const availableCount = [];
    Object.keys(activePropertyLocations).forEach((country) => {
      availableCount.push(country);
    });
    setAvailableCountries(availableCount);
  }, [activePropertyLocations]);

  useEffect(() => {
    const availableLoc = [];
    if (availableCountries.length > 0 && selectedCountries?.length > 0) {
      selectedCountries.forEach((country) => {
        activePropertyLocations[country].forEach((countryLocation) => {
          availableLoc.push({
            locationName: countryLocation,
            locationCountry: country,
          });
        });
      });
    }
    setAvailableLocations(availableLoc);
  }, [availableCountries, selectedCountries]);

  const changeSelectedCountries = (countries) => {
    setSelectedCountries([...countries]);
  };

  const toggleFooter = () => {
    setIsFooterOpen(!isFooterOpen);
  };

  const toggleView = () => {
    setListMode(!list_mode);
  };

  const getLocationObj = (locations, countries) => {
    const location = {};

    locations.forEach((loc) => {
      const locArr = loc.split(/ \(/);
      const city = locArr[0];
      const country = locArr[1].substring(0, locArr[1].length - 1);

      if (!(country in location)) {
        location[country] = [];
      }
      location[country].push(city);
    });

    countries.forEach((country) => {
      if (!(country in location)) {
        location[country] = [];
      }
    });

    return location;
  };

  const updateFilters = async (searchFilters) => {
    setSearch(searchFilters);
    setPage(0);
    if (JSON.stringify(searchFilters) === JSON.stringify({})) {
      const fetched = await generalService.getProperties(initialDataLimit, 0, false);
      setProperties(fetched);
    } else {
      const fetched = await generalService.filterProperty(
        initialDataLimit,
        {
          ...searchFilters,
          location: getLocationObj(
            searchFilters.location || [],
            searchFilters.countries || []
          ),
        },
        0
      );
      setProperties(fetched);
    }
  };

  const fetchMoreProperties = async (page) => {
    setPage(page);
    if (JSON.stringify(search) === JSON.stringify({})) {
      const fetched = await generalService.getProperties(
        initialDataLimit,
        page
      );
      setProperties({
        ...fetched,
        data: [...properties.data, ...fetched.data],
      });
    } else {
      const fetched = await generalService.filterProperty(
        initialDataLimit,
        {
          ...search,
          location: getLocationObj(
            search.location || [],
            search.countries || []
          ),
        },
        page
      );
      setProperties({
        ...fetched,
        data: [...properties.data, ...fetched.data],
      });
    }
  };

  return (
    <>
      {(!properties || Object.keys(properties).length === 0) && <Loader />}
      {properties && properties.data && (
        <Container className="search-page" maxWidth={false}>
          <div className="search-bar">
            <SearchBar
              search={search}
              availableCountries={availableCountries}
              availableLocations={availableLocations}
              activePropertyLocations={activePropertyLocations}
              changeSelectedCountries={changeSelectedCountries}
              properties={properties}
              onUpdateFilters={(searchFilters) => updateFilters(searchFilters)}
            />
          </div>
          {/* <div onScroll={trackScrolling} ref={myRef}> */}
          {alert.message && (
            <NotificationBox
              open={true}
              variant={alert.type}
              message={alert.message}
            />
          )}
          <StickyContainer>
            {properties.data.length > 0 ? (
              <Grid container spacing={6} className="listing-container">
                <Grid item xs={12} sm={6} md={8} className="property-list-view">
                  <InfiniteScroll
                    dataLength={properties.data.length}
                    next={() => {
                      fetchMoreProperties(page + 1);
                    }}
                    hasMore={properties.data.length < properties.total}
                    loader={
                      <CircularProgress
                        className="fetch-loader"
                        size={40}
                        variant="indeterminate"
                      />
                    }
                  >
                    <SearchResultsListings
                      properties={properties.data}
                      search={search}
                      updateFilters={updateFilters}
                    />
                  </InfiniteScroll>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="property-map-view">
                  <Hidden xsDown>
                    <PropertyMapView properties={properties.data} />
                  </Hidden>
                  {!list_mode && (
                    <Hidden smUp>
                      <PropertyMapView properties={properties.data} />
                    </Hidden>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Container className="search-page" maxWidth={false}>
              <StickyContainer>

                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6} md={8} className="property-list-view">
                    <Grid container spacing={3} wrap="wrap">
                      {(Array.from(new Array(12))).map((item, index) => (
                        <PropertyCardSkeleton index={index} />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </StickyContainer>
              </Container>
            )}

            {isFooterOpen ? (
              <Button
                variant="contained"
                className="btn-footer-action"
                onClick={toggleFooter}
              >
                <CloseIcon /> Close
              </Button>
            ) : (
              <Button
                variant="contained"
                className="btn-footer-action"
                onClick={toggleFooter}
              >
                GTC, Privacy policy & more
              </Button>
            )}
          </StickyContainer>

          <Button
            variant="contained"
            className="btn-toggle-view"
            onClick={toggleView}
          >
            {list_mode ? (
              <span>
                Map <MapIcon />
              </span>
            ) : (
              <span>
                List <ListIcon />
              </span>
            )}
          </Button>
          {/* </div> */}
        </Container>
      )}
      {isFooterOpen ? (
        <div className="footer-block">
          <FooterComponent />
        </div>
      ) : null}
    </>
  );
};

function mapState(state) {
  const { general, alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedSearchPage = withRouter(
  connect(mapState, actionCreators)(SearchPage)
);
export { connectedSearchPage as SearchPage };
