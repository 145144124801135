import React, { useEffect, useState } from "react";
import "./styles.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { generalActions } from "../../_actions";
import SearchSection from "./SearchSections";

const HeroSection = () => {
  const matches = useMediaQuery("(min-width:991px)");
  const [open, setOpen] = useState(false);
  const [initialDataLimit] = useState(10);
  const [initialPage] = useState(0);

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.general.locations);
  const activePropertyLocations = useSelector(
    (state) => state.general.activePropertyLocations
  );
  const pmProperties = useSelector((state) => state.general.properties);

  useEffect(() => {
    dispatch(generalActions.getAllLocations());
    dispatch(generalActions.getAllPropertyActiveLocations());
  }, [dispatch, initialDataLimit, initialPage]);

  // console.log("locations", locations);
  // console.log("activePropertyLocations", activePropertyLocations);

  return (
    <section className="hero-section">
      <div className="hero-container">
        <h1>Find your dream home & get it for less</h1>
        <SearchSection
          properties={pmProperties}
          locations={locations}
          activePropertyLocations={activePropertyLocations}
        />
      </div>
    </section>
  );
};

export default HeroSection;
