export const calcuteDividendYield = (
  distribution_surplus,
  financing_volume
) => {
  distribution_surplus = Number(distribution_surplus);
  financing_volume = Number(financing_volume);
  if (!isNaN(distribution_surplus / financing_volume)) {
    return (distribution_surplus / financing_volume).toFixed(2);
  }
  return 0;
};

export const formatCurrencyPrice = (propertyPrice, currencyRate) => {
  if (
    currencyRate !== undefined &&
    currencyRate !== null &&
    !isNaN(currencyRate)
  ) {
    currencyRate *= propertyPrice;
    const parts = currencyRate.toString().split(".");
    parts[1] = parseFloat(`0.${parts[1]}`).toFixed(2).split(".")[1];
    if (parts.length > 1) {
      currencyRate =
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        (parts[1] ? "," + parts[1] : "");
    }
    return currencyRate;
  }
  return "-";
};

export const findSectionByKey = (array, key) => {
  const result = array.find((item) => item.section_key === key);
  return result || null;
};
