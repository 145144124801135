import { useEffect, useRef } from "react";

let customEditor = null;

export const useEditorLoader = ({onLoad}) => {

    const timeoutRef = useRef(null);

    const intervalLoad = () => {
        if(timeoutRef.current) {
            clearInterval(timeoutRef.current);
        }

        timeoutRef.current = setInterval(() => {
            if(typeof window.tinymce !== 'undefined' && customEditor !== null) {
                clearInterval(timeoutRef.current);
                onLoad();
            }
        }, 200);
    };

    const loadTinyMCE = () => {

        if(typeof window === 'undefined') return;

        const id = 'tinymce-editor-custom';
        const script = document.createElement('script');

        const scriptExists = document.getElementById(id);
        if(scriptExists) {
            return intervalLoad();
        }

        script.src = '/tinymce/tinymce.min.js';
        script.id = id;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => customEditor = window.tinymce;

        intervalLoad();
    };

    useEffect(() => {
        return () => {
            if(timeoutRef.current) {
                clearInterval(timeoutRef.current);
            }
        };
    }, []);

    return {
        loadTinyMCE
    };

}