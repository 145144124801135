import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import MessageValidator from "../../components/Form/MessageValidator";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { userService } from "../../_services";

const ContactAgentModal = ({ open, closeModal }) => {
  const ref = useRef(null);
  const refTimeout = useRef(null);
  const msgTimeout = 5000;

  const [data, setData] = useState({
    email: "",
    message: "",
  });

  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });

  const [busy, setBusy] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);
    const res = await userService.sendProperyContact(
      data.email,
      data.message,
      property.property_id
    );
    setBusy(false);
    if (!res.status) {
      setAlert({
        message: res.message,
        type: "error",
      });
    } else {
      setAlert({
        message: res.message,
        type: "success",
      });
      setData({
        email: "",
        message: "",
      });
      ref.current.resetValidations();
    }

    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }

    refTimeout.current = setTimeout(() => {
      setAlert({
        message: "",
        type: "",
      });
    }, msgTimeout + 2000);
  };

  const handleChange = (e) => {
    const d = { ...data };
    d[e.target.name] = e.target.value;
    setData(d);
  };

  useEffect(() => {
    return () => {
      if (refTimeout.current) {
        clearTimeout(refTimeout.current);
      }
    };
  }, []);

  return (
    <Dialog
      className="contact-us-form-dialog"
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => closeModal(false)}
      aria-labelledby="contact-agent-dialog-title"
    >
      <DialogTitle id="contact-agent-dialog-title">Contact agent</DialogTitle>
      <DialogContent style={{}}>
        <p>If you have any question, please contact us</p>
        <ValidatorForm
          id="property-contact-form"
          onSubmit={handleSubmit}
          ref={ref}
          style={{ marginTop: "10px", padding: "0", marginBottom: "20px" }}
          autoComplete="off"
        >
          {alert.message && (
            <NotificationBox
              open={true}
              variant={alert.type}
              message={alert.message}
              timeout={msgTimeout}
            />
          )}
          <TextValidator
            fullWidth
            value={data.email || ""}
            onChange={handleChange}
            name="email"
            id="outlined-name"
            label="Email Address"
            margin="normal"
            variant="outlined"
            validators={["required", "isEmail"]}
            errorMessages={[
              "this field is required",
              "Email address is not valid",
            ]}
          />
          <MessageValidator
            fullWidth
            value={data.message || ""}
            onChange={handleChange}
            name="message"
            id="outlined-name"
            label="Your message"
            margin="normal"
            variant="outlined"
            validators={[
              "required",
              "isString",
              "minStringLength:15",
              "maxStringLength:1000",
            ]}
            errorMessages={[
              "this field is required",
              "Message must be a string",
            ]}
          />
          {busy ? (
            <Box sx={{ display: "flex", padding: "34px 0" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Send
            </Button>
          )}
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
};

export default ContactAgentModal;
