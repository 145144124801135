import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Button,
  Avatar,
  Tab,
  Tabs,
  Paper,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { generalActions, alertActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import {
  LanguageTabEn,
  a11yProps,
  TabPanel,
} from "../../components/GeneralComponent/LanguageTabs";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import "./styles.scss";
import { withRouter } from "../../_helpers";
import Editor from "../../components/Editor";

class EditPartnerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: {
        id: 0,
        name_en: "",
        type: "escrow",
        email: "",
        iban: "",
        swift: "",
        description_en: "",
        partner_logoPreview: "",
        partner_logo: "",
      },
      receivedData: false,
      tab_value: 0,
    };

    this.props.clearAlerts();
  }

  componentWillUnmount() {
    this.setState({
      partner: {
        ...this.state.partner,
        id: null,
      },
    });
  }

  componentDidMount() {
    this.props.getPartnerById(this.props.params.id);
  }

  handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      partner: {
        ...prevState.partner,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_value: newValue,
    });
  };

  handleChangeSelect = (event) => {
    this.setState({
      partner: {
        ...this.state.partner,
        type: event.target.value,
      },
    });
  };

  handleCapture = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState((prevState) => ({
        partner: {
          ...prevState.partner,
          partner_logo: file,
          partner_logoPreview: reader.result,
        },
      }));
    };
    reader.readAsDataURL(file);
  };

  selectPartnerLogo = () => {
    document.getElementById("partner_logo").click();
  };

  handleSubmit = () => {
    this.props.updatePartnerById(this.state.partner).then((value) => {
      setTimeout(() => {
        this.props.clearAlerts();
        this.props.navigate("/dashboard/partners");
      }, 2000);
    });
  };

  static getDerivedStateFromProps(props, state) {
    const { partner } = props.general;
    if (
      partner &&
      partner.status === true &&
      partner.data.id !== state.partner.id
    ) {
      return {
        partner: {
          id: partner.data.id,
          name_en: partner.data.name.en,
          type: partner.data.type,
          email: partner.data.email,
          iban: partner.data.iban,
          swift: partner.data.swift,
          description_en: partner.data.description.en,
          partner_logoPreview: partner.data.logo,
          partner_logo: "",
        },
        tab_value: state.tab_value ? state.tab_value : 0,
        receivedData: !state.receivedData,
      };
    }
    return null;
  }

  render() {
    const { partner, tab_value, receivedData } = this.state;
    const { alert } = this.props;

    return (
      <div className="main-content edit-partner-page">
        <DasboardNavBarComponent pageName="manage-partner" />
        <Grid container>
          <Grid item md={8} xs={12}>
            <Card>
              <CardContent>
                {alert.message && (
                  <NotificationBox
                    open={true}
                    variant={alert.type}
                    message={alert.message}
                  />
                )}
                <ValidatorForm
                  className="edit-partner-form partner-form default-form"
                  onSubmit={this.handleSubmit}
                  ref="form"
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Paper square>
                        <Tabs
                          value={tab_value}
                          className="tabs-container"
                          onChange={this.handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                        >
                          <Tab label={<LanguageTabEn />} {...a11yProps(0)} />
                        </Tabs>
                      </Paper>
                      <TabPanel
                        value={tab_value}
                        index={0}
                        className="tab-panel"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Type</label>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                id="demo-simple-select-outlined"
                                value={partner.type}
                                onChange={this.handleChangeSelect}
                              >
                                <MenuItem value="" disabled>
                                  Select partner type
                                </MenuItem>
                                <MenuItem value={"escrow"}>
                                  Escrow agent
                                </MenuItem>
                                <MenuItem value={"listing"}>
                                  Listing agent
                                </MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Name *</label>
                            <TextValidator
                              fullWidth
                              value={partner.name_en}
                              onChange={this.handleChange}
                              id="text-name"
                              name="name_en"
                              className="form-control"
                              placeholder="enter name"
                              variant="outlined"
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Email</label>
                            <TextValidator
                              fullWidth
                              value={partner.email}
                              onChange={this.handleChange}
                              id="text-email"
                              name="email"
                              className="form-control"
                              placeholder="enter email"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">IBAN</label>
                            <TextValidator
                              fullWidth
                              value={partner.iban}
                              onChange={this.handleChange}
                              id="text-iban"
                              name="iban"
                              className="form-control"
                              placeholder="enter iban"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">SWIFT/BIC code</label>
                            <TextValidator
                              fullWidth
                              value={partner.swift}
                              onChange={this.handleChange}
                              id="text-swift"
                              name="swift"
                              className="form-control"
                              placeholder="enter swift"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Description</label>
                            <Editor
                              label="Description *"
                              name="description_en"
                              id="description_en"
                              value={partner.description_en}
                              onChange={this.handleChange}
                              plugins="autolink link image lists preview"
                              toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel
                        value={tab_value}
                        index={1}
                        className="tab-panel"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Type</label>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                id="demo-simple-select-outlined"
                                value={partner.type}
                                onChange={this.handleChangeSelect}
                              >
                                <MenuItem value="" disabled>
                                  Select partner type
                                </MenuItem>
                                <MenuItem value={"escrow"}>
                                  Escrow agent
                                </MenuItem>
                                <MenuItem value={"listing"}>
                                  Listing agent
                                </MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Name *</label>
                            <TextValidator
                              fullWidth
                              value={partner.name_de}
                              onChange={this.handleChange}
                              id="text-name"
                              name="name_de"
                              className="form-control"
                              placeholder="enter name"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Email</label>
                            <TextValidator
                              fullWidth
                              value={partner.email}
                              onChange={this.handleChange}
                              id="text-email"
                              name="email"
                              className="form-control"
                              placeholder="enter email"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">IBAN</label>
                            <TextValidator
                              fullWidth
                              value={partner.iban}
                              onChange={this.handleChange}
                              id="text-iban"
                              name="iban"
                              className="form-control"
                              placeholder="enter iban"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">SWIFT/BIC code</label>
                            <TextValidator
                              fullWidth
                              value={partner.swift}
                              onChange={this.handleChange}
                              id="text-swift"
                              name="swift"
                              className="form-control"
                              placeholder="enter swift"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Description</label>
                            <Editor
                              key={receivedData ? "key1" : "key2"}
                              label="Description *"
                              name="description_de"
                              id="description_de"
                              value={partner.description_de}
                              onChange={this.handleChange}
                              plugins="autolink link image lists preview"
                              toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Button variant="contained" component="label">
                        <span>Upload Logo</span>
                        <input
                          type="file"
                          id="partner_logo"
                          onChange={this.handleCapture}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {partner.partner_logoPreview !== "" && (
                        <Box component="div" m={2} ml={0}>
                          <Avatar
                            style={{
                              cursor: "pointer",
                              height: 150,
                              width: 150,
                              borderRadius: 6,
                            }}
                            alt="Profile Picture"
                            variant="square"
                            onClick={this.selectPartnerLogo}
                            src={partner.partner_logoPreview}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="default"
                        className="btn-primary btn-submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapState(state) {
  const { alert, general } = state;
  return { alert, general };
}
const actionCreators = {
  clearAlerts: alertActions.clear,
  getPartnerById: generalActions.getPartnerById,
  updatePartnerById: generalActions.updatePartnerById,
};

const connectedPage = withRouter(
  connect(mapState, actionCreators)(EditPartnerPage)
);
export { connectedPage as EditPartnerPage };
