import React, { Component } from "react";
import RoomIcon from "@material-ui/icons/Room";
import { connect } from "react-redux";

class PropertyTitle extends Component {
  render() {
    const { property } = this.props;
    return (
      <div className="property-title-box">
        <p className="property-name">{property.title}</p>
        <p className="property-location">
          <RoomIcon />
          {
            <span>
              {this.props.generalState.languageCode === "en"
                ? property.macrolocation_heading_en
                : property.macrolocation_heading_de}
              , {property.address_country}
            </span>
          }
        </p>
      </div>
    );
  }
}

function mapState(state) {
  const { general } = state;
  return { generalState: general };
}

export default connect(mapState)(PropertyTitle);
