import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Paper,
  Popper,
  Grow,
  InputAdornment,
  Select,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { generalActions, alertActions } from "../../_actions";
import "react-input-range/lib/css/index.css";
import "./styles.scss";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.filter1 = React.createRef();
    this.filter2 = React.createRef();
    this.filter3 = React.createRef();
    this.filter4 = React.createRef();

    this.state = {
      filterStatus: {
        filter1: false,
        filter2: false,
        filter3: false,
        filter4: false,
      },
      propertyValueRange: { min: 0, max: 800000 },
      pricePerShareRange: { min: 0, max: 1000 },
      dividendYieldRange: { min: 0, max: 10 },
      search: {},
      availableCountries: [],
      availableLocations: [],
    };
    this.toggleAvailability = this.toggleAvailability.bind(this);
  }

  componentDidMount() {
    const {
      properties,
      search: searchObj,
      availableCountries,
      availableLocations,
    } = this.props;
    if (properties) {
      this.setState((prevState) => ({
        ...prevState,
        search: {
          ...prevState.search,
          ...searchObj,
        },
        propertyValueRange: {
          min: 0,
          max: properties.max_property_value
            ? properties.max_property_value
            : 0,
        },
        pricePerShareRange: {
          min: 0,
          max: properties.max_price_per_share
            ? properties.max_price_per_share
            : 0,
        },
        dividendYieldRange: {
          min: 0,
          max: properties.max_dividend_yield
            ? properties.max_dividend_yield
            : 0,
        },
        availableCountries,
        availableLocations,
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      properties,
      search: searchObj,
      availableCountries,
      availableLocations,
    } = this.props;
    if (
      properties &&
      JSON.stringify(prevProps.properties) !== JSON.stringify(properties) &&
      properties.max_property_value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        search: {
          ...prevState.search,
          ...searchObj,
        },
        propertyValueRange: {
          min: 0,
          max:
            properties.max_property_value > 0
              ? properties.max_property_value
              : this.state.propertyValueRange.max,
        },
        pricePerShareRange: {
          min: 0,
          max:
            properties.max_price_per_share > 0
              ? properties.max_price_per_share
              : this.state.pricePerShareRange.max,
        },
        dividendYieldRange: {
          min: 0,
          max:
            properties.max_dividend_yield > 0
              ? properties.max_dividend_yield
              : this.state.dividendYieldRange.max,
        },
        availableCountries,
        availableLocations,
      }));
    }

    if (JSON.stringify(prevProps.search) !== JSON.stringify(searchObj)) {
      this.setState((prevState) => ({
        ...prevState,
        search: {
          ...prevState.search,
          ...searchObj,
        },
      }));
    }

    if (prevProps.availableCountries.length != availableCountries.length) {
      this.setState((prevState) => ({ ...prevState, availableCountries }));
    }

    const prevStateCountriesLen = prevState.search?.countries?.length || 0;
    const thisStateCountriesLen = this.state.search?.countries?.length || 0;

    if (prevStateCountriesLen !== thisStateCountriesLen) {
      const removedCountry = prevStateCountriesLen > thisStateCountriesLen;
      this.generateAvailableLocationsOptions(removedCountry);
    }
  }

  generateAvailableLocationsOptions(removedCountry) {
    const selectedCountries = this.state.search?.countries
      ? [...this.state.search.countries]
      : [];
    const availableLocations = [];
    if (selectedCountries.length > 0) {
      selectedCountries.forEach((country) =>
        this.props.activePropertyLocations[country].forEach(
          (countryLocation) => {
            availableLocations.push({
              locationName: countryLocation,
              locationCountry: country,
            });
          }
        )
      );
      if (removedCountry) {
        this.setState((prevState) => ({
          search: {
            ...prevState.search,
            location: [],
          },
        }));
      }
    }

    this.setState({ availableLocations });
  }

  handleChange = (event) => {
    let { availableLocations } = this.state;

    if (event.target.name === "countries" && event.target.value.length === 0) {
      this.props.changeSelectedCountries([]);
      availableLocations = [];
    }

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [event.target.name]: event.target.value,
      },
      availableLocations,
    }));
  };

  toggleAvailability(checked) {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        availability: checked,
      },
    }));
    setTimeout(() => this.applyFilter(), 100);
  }

  setFilter = (value, type) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value,
      },
    }));
  };

  applyFilter = () => {
    this.props.clearAlerts();
    this.props.onUpdateFilters(this.state.search);

    const newParams = new URLSearchParams();

    const { search: searchObj } = this.state;
    const figuresWithValues = {};

    // remove keyFigures with both min, max 0
    searchObj["keyFigures"] &&
      Object.keys(searchObj["keyFigures"]).forEach((key) => {
        if (
          searchObj["keyFigures"][key]["min"] ||
          searchObj["keyFigures"][key]["max"]
        ) {
          figuresWithValues[key] = searchObj["keyFigures"][key];
        }
      });

    Object.keys(searchObj).forEach((key) => {
      const value = searchObj[key];
      if (typeof value === "object" && value !== null) {
        if (key !== "keyFigures") {
          newParams.append(key, JSON.stringify(value));
        } else if (Object.keys(figuresWithValues).length) {
          newParams.append(key, JSON.stringify(figuresWithValues));
        }
      } else {
        newParams.append(key, value);
      }
    });

    window.history.pushState({}, "", `?${newParams.toString()}`);
  };

  deleteFilter = (filter) => {
    const {
      [filter]: _,
      [filter === "countries" ? "location" : ""]: __,
      ...remainingSearch
    } = this.state.search;
    const {
      [filter === "countries" ? "availableLocations" : ""]: ___,
      ...remainingState
    } = this.state;
    this.setState({
      ...remainingState,
      search: remainingSearch,
    });
  };

  toggleFilter = (filter) => {
    this.setState((prevState) => ({
      filterStatus: {
        ...prevState.filterStatus,
        filter1: false,
        filter2: false,
        filter3: false,
        filter4: false,
        [filter]: !prevState.filterStatus[filter],
      },
    }));
  };

  formatYeild(value) {
    return value.toFixed(1).toString().replace(".", ",");
  }

  formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  getKeyFiguresValue = (keyFigure, attr) => {
    const { search } = this.state;

    // check if keyFigures and other objects exist and return value, else 0
    return (
      (search.keyFigures &&
        search.keyFigures[keyFigure] &&
        search.keyFigures[keyFigure][attr]) ||
      0
    );
  };

  getStyles = (city, cities) => {
    return {
      fontWeight: cities.indexOf(city) === -1 ? 500 : 800,
      backgroundColor: cities.indexOf(city) === -1 ? "" : "#c9c9c9",
    };
  };

  render() {
    return (
      <div className="search-bar-block">
        <div className="search-form">
          <Button
            variant="outlined"
            className="btn-filter"
            ref={this.filter1}
            endIcon={this.state.filterStatus.filter1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
            onClick={() => {
              this.toggleFilter("filter1");
            }}
          >
            Price
          </Button>
          <Button
            variant="outlined"
            className="btn-filter"
            ref={this.filter2}
            onClick={() => {
              this.toggleFilter("filter2");
            }}
            endIcon={this.state.filterStatus.filter2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
          >
            Key Figures
          </Button>
          <Button
            variant="outlined"
            className="btn-filter"
            ref={this.filter3}
            onClick={() => {
              this.toggleFilter("filter3");
            }}
            endIcon={this.state.filterStatus.filter3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
          >
            Category Type
          </Button>
          <Button
            variant="outlined"
            className="btn-filter"
            ref={this.filter4}
            onClick={() => {
              this.toggleFilter("filter4");
            }}
            endIcon={this.state.filterStatus.filter4 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
          >
            Location
          </Button>

          <Popper
            className="filter-box"
            open={this.state.filterStatus.filter1}
            anchorEl={this.filter1.current}
            role={undefined}
            transition
            disablePortal
            placement={"bottom-start"}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper className="filter-content">
                  <p className="filter-title">Price range</p>
                  <br />

                  <ValidatorForm className="popper-form-wrapper" autoComplete="off" instantValidate>
                    <TextValidator
                      fullWidth
                      value={
                        this.state.search.propertyValue?.min
                          ? this.state.search.propertyValue.min
                          : 0
                      }
                      onChange={(e) => {
                        const min =
                          parseInt(e.target.value) ||
                          this.state.propertyValueRange.min;
                        const max = this.state.search.propertyValue?.max
                          ? this.state.search.propertyValue.max
                          : 0;
                        min >= 0 &&
                          this.setFilter({ min, max }, "propertyValue");
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      min={this.state.propertyValueRange.min}
                      max={
                        this.state.search.propertyValue?.max
                          ? this.state.search.propertyValue.max
                          : 0
                      }
                      variant="outlined"
                      size="small"
                      type="number"
                      label="Min"
                      validators={["required", "isNumber"]}
                      errorMessages={["This field is required", ""]}
                    />
                    <TextValidator
                      fullWidth
                      value={
                        this.state.search.propertyValue?.max
                          ? this.state.search.propertyValue.max
                          : 0
                      }
                      onChange={(e) => {
                        const min = this.state.search.propertyValue?.min
                          ? this.state.search.propertyValue.min
                          : 0;
                        const max = parseInt(e.target.value) || 0;
                        max >= 0 &&
                          this.setFilter({ min, max }, "propertyValue");
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      min={
                        this.state.search.propertyValue?.min
                          ? this.state.search.propertyValue.min
                          : 0
                      }
                      max={
                        this.state.search.propertyValue?.max
                          ? this.state.search.propertyValue.max
                          : 0
                      }
                      variant="outlined"
                      size="small"
                      type="number"
                      label="Max"
                      validators={["required", "isNumber"]}
                    />
                  </ValidatorForm>

                  <div className="action-footer">
                    <Button
                      className="btn-delete"
                      onClick={() => {
                        this.deleteFilter("propertyValue");
                        this.toggleFilter("filter1");
                        setTimeout(() => this.applyFilter(), 100);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      className="btn-save"
                      onClick={() => {
                        this.toggleFilter("filter1");
                        this.applyFilter();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Popper
            className="filter-box"
            open={this.state.filterStatus.filter2}
            anchorEl={this.filter2.current}
            role={undefined}
            transition
            disablePortal
            placement={"bottom-start"}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper className="filter-content">
                  <p className="filter-title">Key Figures</p>

                  <div
                    className="range-slider"
                    style={{ overflowY: "auto" }}
                  >
                    <div className="range-value">
                    <Box className="key-figure-filter">
                        <Box>
                          <Typography
                            style={{
                              display: "block",
                              padding: 0,
                              marginBottom: "5px",
                            }}
                          >
                            Bedrooms
                          </Typography>
                        </Box>
                        <ValidatorForm
                          onSubmit={() => {}}
                          autoComplete="off"
                          instantValidate
                          style={{
                            marginLeft: "2px",
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10,
                          }}
                        >
                          <TextValidator
                            value={this.getKeyFiguresValue("Bedrooms", "min")}
                            onChange={(e) => {
                              const min = parseInt(e.target.value) || 0;
                              const max = this.getKeyFiguresValue(
                                "Bedrooms",
                                "max"
                              );
                              min >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    Bedrooms: { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={0}
                            max={this.getKeyFiguresValue("Bedrooms", "max")}
                            type="number"
                            label="Min"
                            validators={["required", "isNumber"]}
                            errorMessages={["This field is required", ""]}
                            variant="outlined"
                            size="small"
                          />
                          <TextValidator
                            value={this.getKeyFiguresValue("Bedrooms", "max")}
                            onChange={(e) => {
                              const min = this.getKeyFiguresValue(
                                "Bedrooms",
                                "min"
                              );
                              const max = parseInt(e.target.value) || 0;
                              max >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    Bedrooms: { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={this.getKeyFiguresValue("Bedrooms", "min")}
                            max={this.getKeyFiguresValue("Bedrooms", "max")}
                            type="number"
                            label="Max"
                            validators={["required", "isNumber"]}
                            variant="outlined"
                            size="small"
                          />
                        </ValidatorForm>
                      </Box>
                      <Box className="key-figure-filter">
                        <Typography
                          style={{
                            display: "block",
                            padding: 0,
                            marginBottom: "5px",
                          }}
                        >
                          Bathrooms
                        </Typography>
                        <ValidatorForm
                          onSubmit={() => {}}
                          autoComplete="off"
                          instantValidate
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10,
                          }}
                        >
                          <TextValidator
                            value={this.getKeyFiguresValue("Bathrooms", "min")}
                            onChange={(e) => {
                              const min = parseInt(e.target.value) || 0;
                              const max = this.getKeyFiguresValue(
                                "Bathrooms",
                                "max"
                              );
                              min >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    Bathrooms: { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={0}
                            max={this.getKeyFiguresValue("Bathrooms", "max")}
                            type="number"
                            label="Min"
                            validators={[
                              "required",
                              "isNumber",
                              // 'aboveThan',
                            ]}
                            errorMessages={["This field is required", ""]}
                            variant="outlined"
                            size="small"
                          />
                          <TextValidator
                            value={this.getKeyFiguresValue("Bathrooms", "max")}
                            onChange={(e) => {
                              const min = this.getKeyFiguresValue(
                                "Bathrooms",
                                "min"
                              );
                              const max = parseInt(e.target.value) || 0;
                              max >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    Bathrooms: { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={this.getKeyFiguresValue("Bathrooms", "min")}
                            max={this.getKeyFiguresValue("Bathrooms", "max")}
                            type="number"
                            label="Max"
                            validators={["required", "isNumber"]}
                            variant="outlined"
                            size="small"
                          />
                        </ValidatorForm>
                      </Box>
                      <Box className="key-figure-filter">
                        <Typography
                          style={{
                            display: "block",
                            padding: 0,
                            marginBottom: "5px",
                          }}
                        >
                          Parking Spaces
                        </Typography>
                        <ValidatorForm
                          onSubmit={() => {}}
                          autoComplete="off"
                          instantValidate
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10,
                          }}
                        >
                          <TextValidator
                            value={this.getKeyFiguresValue(
                              "Parking Spaces",
                              "min"
                            )}
                            onChange={(e) => {
                              const min = parseInt(e.target.value) || 0;
                              const max = this.getKeyFiguresValue(
                                "Parking Spaces",
                                "max"
                              );
                              min >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    "Parking Spaces": { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={0}
                            max={this.getKeyFiguresValue(
                              "Parking Spaces",
                              "max"
                            )}
                            type="number"
                            label="Min"
                            validators={["required", "isNumber"]}
                            errorMessages={["This field is required", ""]}
                            variant="outlined"
                            size="small"
                          />
                          <TextValidator
                            value={this.getKeyFiguresValue(
                              "Parking Spaces",
                              "max"
                            )}
                            onChange={(e) => {
                              const min = this.getKeyFiguresValue(
                                "Parking Spaces",
                                "min"
                              );
                              const max = parseInt(e.target.value) || 0;
                              max >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    "Parking Spaces": { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={this.getKeyFiguresValue(
                              "Parking Spaces",
                              "min"
                            )}
                            max={this.getKeyFiguresValue(
                              "Parking Spaces",
                              "max"
                            )}
                            type="number"
                            label="Max"
                            validators={["required", "isNumber"]}
                            variant="outlined"
                            size="small"
                          />
                        </ValidatorForm>
                      </Box>
                      <Box className="key-figure-filter">
                        <Typography
                          style={{
                            display: "block",
                            padding: 0,
                            marginBottom: "5px",
                          }}
                        >
                          Sq Feet
                        </Typography>
                        <ValidatorForm
                          onSubmit={() => {}}
                          autoComplete="off"
                          instantValidate
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10,
                          }}
                        >
                          <TextValidator
                            value={this.getKeyFiguresValue("Sq Feet", "min")}
                            onChange={(e) => {
                              const min = parseInt(e.target.value) || 0;
                              const max = this.getKeyFiguresValue(
                                "Sq Feet",
                                "max"
                              );
                              min >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    "Sq Feet": { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={0}
                            max={this.getKeyFiguresValue("Sq Feet", "max")}
                            type="number"
                            label="Min"
                            validators={["required", "isNumber"]}
                            errorMessages={["This field is required", ""]}
                            variant="outlined"
                            size="small"
                          />
                          <TextValidator
                            value={this.getKeyFiguresValue("Sq Feet", "max")}
                            onChange={(e) => {
                              const min = this.getKeyFiguresValue(
                                "Sq Feet",
                                "min"
                              );
                              const max = parseInt(e.target.value) || 0;
                              max >= 0 &&
                                this.setFilter(
                                  {
                                    ...this.state.search.keyFigures,
                                    "Sq Feet": { min, max },
                                  },
                                  "keyFigures"
                                );
                            }}
                            min={this.getKeyFiguresValue("Sq Feet", "min")}
                            max={this.getKeyFiguresValue("Sq Feet", "max")}
                            type="number"
                            label="Max"
                            validators={["required", "isNumber"]}
                            variant="outlined"
                            size="small"
                          />
                        </ValidatorForm>
                      </Box>
                    </div>
                  </div>

                  <div className="action-footer">
                    <Button
                      className="btn-delete"
                      onClick={() => {
                        this.deleteFilter("keyFigures");
                        this.toggleFilter("filter2");
                        setTimeout(() => this.applyFilter(), 100);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      className="btn-save"
                      onClick={() => {
                        this.toggleFilter("filter2");
                        this.applyFilter();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Popper
            className="filter-box"
            open={this.state.filterStatus.filter3}
            anchorEl={this.filter3.current}
            role={undefined}
            transition
            disablePortal
            placement={"bottom-start"}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper className="filter-content">
                  <p className="filter-title">Category Type of Property</p>
                  <br />

                  <Select
                    className="form-control"
                    multiple
                    inputProps={{
                      name: "categoryType",
                      id: "search-category-type",
                    }}
                    disableUnderline
                    MenuProps={{
                      getContentAnchorEl: () => null,
                    }}
                    value={
                      this.state.search?.categoryType
                        ? this.state.search.categoryType
                        : []
                    }
                    onChange={this.handleChange}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span>None</span>;
                      }
                      return selected
                        .map(
                          (type) => type[0].toUpperCase() + type.substring(1)
                        )
                        .join(", ");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      None
                    </MenuItem>
                    <MenuItem value="apartment">Apartment</MenuItem>
                    <MenuItem value="condo">Condo</MenuItem>
                    <MenuItem value="penthouse">Penthouse</MenuItem>
                    <MenuItem value="house">House</MenuItem>
                    <MenuItem value="land">Land</MenuItem>
                    <MenuItem value="hotel">Hotel</MenuItem>
                    <MenuItem value="building">Building</MenuItem>
                    <MenuItem value="villa">Villa</MenuItem>
                    <MenuItem value="duplex">Duplex</MenuItem>
                    <MenuItem value="office space">Office Space</MenuItem>
                  </Select>

                  <div className="action-footer">
                    <Button
                      className="btn-delete"
                      onClick={() => {
                        this.deleteFilter("categoryType");
                        this.toggleFilter("filter3");
                        setTimeout(() => this.applyFilter(), 100);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      className="btn-save"
                      onClick={() => {
                        this.toggleFilter("filter3");
                        this.applyFilter();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Popper
            className="filter-box"
            open={this.state.filterStatus.filter4}
            anchorEl={this.filter4.current}
            role={undefined}
            transition
            disablePortal
            placement={"bottom-start"}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper className="filter-content">
                  <div
                    className=""
                    style={{
                      overflowY: "auto",
                      // height: "200px"
                      overflowX: "hidden",
                    }}
                  >
                    <div className="range-value location-range-value">
                      {this.state.availableCountries.length === 0 ? (
                        <>
                          <Typography
                            style={{
                              display: "block",
                              padding: 0,
                              marginBottom: "5px",
                            }}
                          >
                            Loading available locations...
                          </Typography>
                          <LinearProgress />
                        </>
                      ) : (
                        <>
                          <Box className="location-filter">
                            <Typography
                              style={{
                                display: "block",
                                padding: 0,
                                marginBottom: "5px",
                              }}
                            >
                              Country
                            </Typography>
                            <Select
                              className="form-control"
                              multiple
                              inputProps={{
                                name: "countries",
                                id: "search-country",
                              }}
                              disableUnderline
                              MenuProps={{
                                getContentAnchorEl: () => null,
                              }}
                              value={
                                this.state.search?.countries
                                  ? this.state.search.countries
                                  : []
                              }
                              onChange={this.handleChange}
                              displayEmpty
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <span>None</span>;
                                }
                                return selected.join(", ");
                              }}
                              size="small"
                              variant="outlined"
                            >
                              <MenuItem value="" disabled>
                                None
                              </MenuItem>
                              {this.state.availableCountries &&
                                this.state.availableCountries.length > 0 &&
                                this.state.availableCountries.map(
                                  (country, index) => (
                                    <MenuItem
                                      key={index}
                                      value={country}
                                      style={this.getStyles(
                                        country,
                                        this.state.search?.countries
                                          ? this.state.search.countries
                                          : []
                                      )}
                                    >
                                      {country}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </Box>
                          <Box className="location-filter">
                            <Typography
                              style={{
                                display: "block",
                                padding: 0,
                                marginBottom: "5px",
                              }}
                            >
                              Location
                            </Typography>
                            <Select
                              className="form-control"
                              multiple
                              disabled={
                                !this.state.availableLocations.length > 0
                              }
                              MenuProps={{
                                getContentAnchorEl: () => null,
                              }}
                              inputProps={{
                                name: "location",
                                id: "search-location",
                              }}
                              disableUnderline
                              value={
                                this.state.search?.location
                                  ? this.state.search.location
                                  : []
                              }
                              onChange={this.handleChange}
                              displayEmpty
                              size="small"
                              variant="outlined"
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <span>None</span>;
                                }
                                return selected.join(", ");
                              }}
                            >
                              <MenuItem value="" disabled>
                                None
                              </MenuItem>
                              {this.state.availableLocations &&
                                this.state.availableLocations.length > 0 &&
                                this.state.availableLocations.map(
                                  (location, index) => (
                                    <MenuItem
                                      key={index}
                                      value={`${location.locationName} (${location.locationCountry})`}
                                      style={this.getStyles(
                                        `${location.locationName} (${location.locationCountry})`,
                                        this.state.search?.location
                                          ? this.state.search.location
                                          : []
                                      )}
                                    >
                                      {`${location.locationName} (${location.locationCountry})`}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </Box>
                        </>
                      )}
                    </div>
                  </div>

                  {this.state.availableCountries.length === 0 ? (
                    ""
                  ) : (
                    <div className="action-footer">
                      <Button
                        className="btn-delete"
                        onClick={() => {
                          this.deleteFilter("countries");
                          this.props.changeSelectedCountries([]);
                          this.toggleFilter("filter4");
                          setTimeout(() => this.applyFilter(), 100);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        className="btn-save"
                        onClick={() => {
                          const countries = this.state.search.countries || [];
                          this.props.changeSelectedCountries(countries);
                          this.toggleFilter("filter4");
                          this.applyFilter();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  filterProperty: generalActions.filterProperty,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(SearchBar);
