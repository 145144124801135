import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Hidden, IconButton, Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { HeaderLinks } from "./HeaderLinks";
import "./styles.scss";
import HeaderLogo from "../DashboardComponent/common/HeaderLogo";
import ILogo from '../../assets/images/Immobilium-logo.png'
import MenuImage from '../../assets/images/menu-icon.svg'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "sticky",
    top: 0,
    zIndex: 2000,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

function HeaderComponent({
  users,
  stickyPosition = 0,
  noStickyClass = "noSticky",
  ...props
}) {
  const location = useLocation();
  const isHomePage = ["/", "/home"].includes(location.pathname);
  const classes = useStyles();
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [theme, setTheme] = React.useState("light");
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();

  const handleNavDrawerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };


  useEffect(() => {
    setTheme(users?.profile_data?.theme || "light");
  }, [users]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = (event) => {
    let scrollTop = document.documentElement.scrollTop;
    setIsSticky(scrollTop > stickyPosition);
  };

  return (
    <div
      className={`app-navbar-container ${classes.root} ${
        isSticky ? "isSticky" : (noStickyClass || '')
      } ${isHomePage ? "home-page-nav" : ""}`}
    >
      <AppBar position="static" color="inherit" className="navbar">
        <Toolbar disableGutters>
          <Hidden mdUp>
            <HeaderLogo users={users} mobile={true} />
            <IconButton
              className="btn-menu-toggle"
              color=""
              aria-label="open drawer"
              onClick={handleNavDrawerToggle}
            >
              <img src={MenuImage} />
            </IconButton>
          </Hidden>

          <Hidden smDown implementation="css">
            <HeaderLinks navigate={navigate} />
          </Hidden>
        </Toolbar>

        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            open={mobileNavOpen}
            onClose={handleNavDrawerToggle}
          >
            <div className="header-link-container">
              <HeaderLinks
                navControl={handleNavDrawerToggle}
                navigate={navigate}
              />
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    </div>
    // <header className="custom-header">
    //   <div className="app-bar">
    //     <div className="logo-wrapper">
    //     <img src={ILogo} alt="menu" />
    //     </div>
    //     <div className="nav-wrapper">
    //       <HeaderLinks />
    //     </div>
    //     <div className="account-wrapper">
    //       <img src={MenuImage} alt="menu" />
    //     </div>
    //   </div>
    // </header>
  );
}

function mapState(state) {
  const { users } = state;
  return { users };
}

export default connect(mapState)(HeaderComponent);
