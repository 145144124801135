import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EthIcon from "../../assets/images/eth.svg";
import BtcIcon from "../../assets/images/btn.svg";
import MapIcon from "../../assets/images/place.svg";
import { Button } from "@material-ui/core";
import { secureStorage } from "../../_services";
import { userActions } from "../../_actions";
import TradeModal from "../../components/DashboardComponent/modals/TradeModal";
import { useNavigate } from "react-router-dom";
import ContactAgentModal from "./ContactAgentModal";
import ShareButton from "../../components/Share";
import { order } from "@0bsnetwork/zbs-transactions";
import removePlusCode from "../../_helpers/removePlusCode";
const TOKEN_WALLET_PAGE_URL = "/dashboard/tokenwallet";

const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return x;
};

const formatPrice = (val) => {
  if (val !== undefined && val !== null && !isNaN(val)) {
    const parts = val.toString().split(".");
    if (parts.length > 1) {
      val =
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        (parts[1] ? "." + parts[1] : "");
    }
    val = numberWithCommas(val);
    return val;
  }
  return "-";
};

const formatCoinPrice = (val) => {
  if (val !== undefined && val !== null && !isNaN(val)) {
    const parts = val.toString().split(".");
    parts[1] = parseFloat(`0.${parts[1]}`).toFixed(8).split(".")[1];
    if (parts.length > 1) {
      val =
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        (parts[1] ? "," + parts[1] : "");
    }
    return val;
  }
  return "-";
};

const formatCurrencyPrice = (propertyPrice, currencyRate) => {
  if (
    currencyRate !== undefined &&
    currencyRate !== null &&
    !isNaN(currencyRate)
  ) {
    currencyRate *= propertyPrice;
    const parts = currencyRate.toString().split(".");
    parts[1] = parseFloat(`0.${parts[1]}`).toFixed(2).split(".")[1];
    if (parts.length > 1) {
      currencyRate =
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        (parts[1] ? "," + parts[1] : "");
    }
    return currencyRate;
  }
  return "-";
};

const InfoSection = ({
  property,
  propertyId,
  // onModalOpen,
  // onModalClose,
  toggleNotification,
  title,
  description,
}) => {
  const navigate = useNavigate();
  const [modalStatus, setModalStatus] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [contactAgentModal, setContactAgentModal] = useState(false);
  const [order_details, setOrder_details] = useState({
    _property_id: property.property_id,
    _order_reference_id: null,
    _user_reference_id: 0,
    order_type: "Buy",
    quantity: 0,
    price: 0,
    amount: 0,
    fee: 0,
    fee_amount: 0,
    total_amount: 0,
    payment_method: "account balance",
    order_complete: false,
  });

  const { coinRate, currencyRates, users, authentication } = useSelector(
    (state) => ({
      coinRate: state.general.coinRate,
      currencyRates: state.general.currencyRates,
      users: state.users,
      authentication: state.authentication,
    })
  );

  const dispatch = useDispatch();

  // Dispatching actions
  const parseJwt = () => dispatch(userActions.parseJwt());
  const getUserRecentTransactions = () =>
    dispatch(userActions.getUserRecentTransactions());
  const getUsersOrders = () => dispatch(userActions.getUsersOrders());
  const getUserDetails = () => dispatch(userActions.getUserDetails());
  const getSettings = () => dispatch(userActions.getSettings());

  const setInitialValues = () => {
    const tokenInfo = parseJwt();
    if (tokenInfo && tokenInfo.status) {
      const { property_price } = property;
      const quantity = 1,
        fee = 2.5;

      let fee_amount = (property_price * fee) / 100;
      if (fee_amount % 1 !== 0) {
        fee_amount = parseFloat(fee_amount.toFixed(2));
      }

      let _user_reference_id = secureStorage.getItem("user_id");
      try {
        if (users.profile_data.user_id) {
          _user_reference_id = users.profile_data.user_id;
        }
      } catch (err) {
        console.log("Error occurred", err);
      }

      setIsUserLoggedIn(true);
      setOrder_details({
        ...order_details,
        quantity,
        fee,
        fee_amount,
        _user_reference_id,
        _property_id: propertyId,
        price: property_price,
        amount: quantity * property_price,
        total_amount: property_price + fee_amount,
      });
    }
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  const openOrderBookModal = () => {
    const walletAddress = secureStorage.getItem("wallet_address");
    if (isUserLoggedIn) {
      if (!walletAddress) {
        // this is not working
        navigate(TOKEN_WALLET_PAGE_URL);
      } else if (propertyId) {
        getUserDetails();
        getSettings();
        getUserRecentTransactions(propertyId);
        getUsersOrders(propertyId);
        setModalStatus(true);
      }
    } else {
      navigate("/login");
    }
  };

  const getButton = (
    userId = order_details._user_reference_id,
    propertyOwnerId = property._user_id,
    isSold = property.is_sold
  ) => {
    if (isSold) {
      return getButtonComponent("disabled", "This property is already sold!");
    } else if (userId === propertyOwnerId) {
      return getButtonComponent("disabled", "You own this property");
    } else if (!secureStorage.getItem("is_kyc_verified")) {
      if (!userId) {
        return getButtonComponent(
          "disabled",
          "You must Register and be KYC verified in order to purchase property"
        );
      } else {
        return getButtonComponent(
          "disabled",
          "You must Register and be KYC verified in order to purchase property",
          true
        );
      }
    } else if (!secureStorage.getItem("wallet_address")) {
      return getButtonComponent(
        "disabled",
        "Your token wallet isn't configured properly, please go to the Wallet Page to rectify the issue before you can purchase properties"
      );
    }

    return getButtonComponent();
  };

  const getButtonComponent = (type, message, kyc = false) => {
    if (type === "disabled") {
      return (
        <>
          <Button disabled type="button" variant="outlined" color="default">
            Purchase Property
          </Button>
          <p>
            * {message}
            {kyc ? (
              <>
                <br />
                <Link to={"/users/kyc"}>
                  Get KYC verified, it only takes a couple of minutes
                </Link>
              </>
            ) : null}
          </p>
        </>
      );
    } else {
      return (
        <Button onClick={openOrderBookModal} variant="outlined" color="primary">
          Purchase Property
        </Button>
      );
    }
  };

  return (
    <section className="info-section">
      <div className="info-section-container">
        <div className="info-content">
          {property.property_currency === "USD" ? (
            <>
              <p className="main-price">
                &#36; {formatPrice(property.property_price)}
              </p>
              <p>Price in different currencies:</p>
              <div className="crypto-price-wrapper">
                <div>
                  <p className="crypto-price">
                    <span>
                      &euro;{"   "}
                      {currencyRates.EUR
                        ? formatCurrencyPrice(
                            property.property_price,
                            currencyRates.EUR
                          )
                        : "-"}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="crypto-price">
                    <img src={BtcIcon} alt="bitcoin" />
                    <span>
                      {coinRate?.BTC
                        ? formatCoinPrice(
                            property.property_price / coinRate.BTC
                          )
                        : "-"}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="crypto-price">
                    <img src={EthIcon} alt="eth" />
                    <span>
                      {coinRate?.ETH
                        ? formatCoinPrice(
                            property.property_price / coinRate.ETH
                          )
                        : "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="property-location">
                <p>
                  <img src={MapIcon} alt="location" />
                  {removePlusCode(property.address_line1)}
                </p>
              </div>
            </>
          ) : (
            <>
              <p className="main-price">
                &#36;{" "}
                {formatCurrencyPrice(
                  property.property_price,
                  1 / currencyRates.EUR
                )}
              </p>
              <p>Price in different currencies:</p>
              <div className="crypto-price-wrapper">
                <div>
                  <p className="crypto-price">
                    <span>
                      &euro;{"   "}
                      {formatPrice(property.property_price)}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="crypto-price">
                    <img src={BtcIcon} alt="bitcoin" />
                    <span>
                      {coinRate?.BTC
                        ? formatCoinPrice(
                            (property.property_price *
                              (1 / currencyRates.EUR)) /
                              coinRate.BTC
                          )
                        : "-"}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="crypto-price">
                    <img src={EthIcon} alt="eth" />
                    <span>
                      {coinRate?.ETH
                        ? formatCoinPrice(
                            (property.property_price *
                              (1 / currencyRates.EUR)) /
                              coinRate.ETH
                          )
                        : "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="property-location">
                <p>
                  <img src={MapIcon} alt="location" />
                  {removePlusCode(property.address_line1)}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="info-cta">
          <div className="info-cta-wrapper">
            {getButton()}
            <Button
              onClick={() => setContactAgentModal(true)}
              type="button"
              variant="outlined"
              color="default"
            >
              Contact agent
            </Button>
            <ShareButton title={title} text={description} />
          </div>
        </div>
      </div>
      <TradeModal
        open={modalStatus}
        orderDetails={order_details}
        property={property}
        user={users.profile_data}
        handleClose={() => setModalStatus(false)}
        toggleNotification={toggleNotification}
      />
      <ContactAgentModal
        open={contactAgentModal}
        closeModal={setContactAgentModal}
      />
    </section>
  );
};

export default InfoSection;
