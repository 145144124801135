import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Box,
  TablePagination,
} from "@material-ui/core";
import { alertActions, userActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { useConfirm } from "../../components/Confirm";
import DepositModal from "../../components/DashboardComponent/modals/DepositModal";
import WithdrawModal from "../../components/DashboardComponent/modals/WithdrawModal";
import DepositInfoAdminModal from "../../components/DashboardComponent/modals/DepositInfoAdminModal";
import WithdrawInfoAdminModal from "../../components/DashboardComponent/modals/WithdrawInfoAdminModal";
import { userManageService } from "../../_services";
import "./styles.scss";

const ManageUserPage = ({
  alert,
  clearAlerts,
  successAlerts,
  errorAlerts,
  depositMoney,
  withdrawMoney,
}) => {
  const { isConfirmed } = useConfirm();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState({
    size: 10,
    page: 0,
    count: 0,
  });

  const [selectedUser, setSelectedUser] = useState({
    id: 0,
    first_name: "",
    last_name: "",
  });
  const [deposit, setDeposit] = useState({
    amount: "0",
    modalStatus: false,
    confirmModalStatus: false,
  });
  // const [tokens, setTokens] = useState({
  //   amount: "0",
  //   modalStatus: false,
  //   confirmModalStatus: false,
  // });
  const [withdraw, setWithdraw] = useState({
    amount: "0",
    modalStatus: false,
    confirmModalStatus: false,
  });

  useEffect(() => {
    loadUsers(page);
  }, []);

  const loadUsers = (page) => {
    (async () => {
      const { error, data, pagination } = await userManageService.getUsers(
        page.page,
        page.size
      );
      data && setUsers(data);
      pagination &&
        setPage({
          ...page,
          ...pagination,
        });
    })();
  };

  const openDepositModal = (user_id) => {
    setDeposit({
      ...deposit,
      modalStatus: true,
    });
    setSelectedUser({ id: user_id });
  };

  // const openTokensModal = (user_id) => {
  //   setTokens({
  //     ...tokens,
  //     modalStatus: true,
  //   });
  //   setSelectedUser({ id: user_id });
  // };

  const closeDepositModal = () => {
    setDeposit({
      ...deposit,
      modalStatus: false,
    });
  };

  const openWithdrawModal = (user_id) => {
    setWithdraw({
      ...withdraw,
      modalStatus: true,
    });
    setSelectedUser({ id: user_id });
  };

  const closeWithdrawModal = () => {
    setWithdraw({
      ...withdraw,
      modalStatus: false,
    });
  };

  const openConfirmModal = (action) => {
    const user = users.filter((user) => user.user_id === selectedUser.id)[0];
    setSelectedUser({
      ...selectedUser,
      first_name: user.first_name,
      last_name: user.last_name,
    });
    if (action === "deposit") {
      setDeposit({
        ...deposit,
        confirmModalStatus: true,
      });
    } else {
      setWithdraw({
        ...withdraw,
        confirmModalStatus: true,
      });
    }
  };

  const closeConfirmModal = (action) => {
    if (action === "deposit") {
      setDeposit({
        ...deposit,
        modalStatus: false,
        confirmModalStatus: false,
      });
    } else {
      setWithdraw({
        ...withdraw,
        modalStatus: false,
        confirmModalStatus: false,
      });
    }
  };

  const handleChange = (event) => {
    const amount = event.target.value;
    if (event.target.name === "deposit") {
      setDeposit({ ...deposit, amount });
    } else {
      setWithdraw({ ...withdraw, amount });
    }
  };

  const nextDepositModal = () => {
    closeDepositModal();
    openConfirmModal("deposit");
  };

  const previousDepositModal = () => {
    setDeposit({
      ...deposit,
      modalStatus: true,
      confirmModalStatus: false,
    });
  };

  const nextWithdrawModal = () => {
    closeWithdrawModal();
    openConfirmModal("withdraw");
  };

  const previousWithdrawModal = () => {
    setWithdraw({
      ...withdraw,
      modalStatus: true,
      confirmModalStatus: false,
    });
  };

  const handleDeposit = async () => {
    const user_id = selectedUser.id;
    await depositMoney(user_id, {
      deposit: deposit.amount,
    });
    closeConfirmModal("deposit");
  };

  const handleWithdraw = async () => {
    const user_id = selectedUser.id;
    await withdrawMoney(user_id, {
      withdraw: withdraw.amount,
    });
    closeConfirmModal("withdraw");
  };

  const handleDelete = async (user_id) => {
    clearAlerts();
    const confirmed = await isConfirmed({
      title: "Delete User?",
      message: "Are you sure you want to delete this user?",
      okay: "Yes",
      cancel: "No",
    });

    if (!confirmed) {
      return;
    }

    try {
      const { error, message } = await userManageService.deleteUser(user_id);
      if (error) {
        errorAlerts(message || "Something went wrong");
      } else {
        successAlerts("User updated successfully");
        loadUsers(page);
      }
    } catch (e) {
      console.log(e);
      errorAlerts("Something went wrong");
    }
  };

  return (
    <div className="main-content manage-user-page">
      <DasboardNavBarComponent pageName="manage-users" />

      {alert.message && (
        <NotificationBox
          open={true}
          variant={alert.type}
          message={alert.message}
        />
      )}

      <Box mb={3}>
        <Link to="/dashboard/users/add">
          <Button variant="contained" color="primary">
            Add User
          </Button>
        </Link>
      </Box>

      <Card>
        <CardContent className="table-scroll">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Is Verified</th>
                <th>Is Admin</th>
                {/* <th>IBAN</th> */}
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!users.length && (
                <tr>
                  <td colSpan={8} align="center" style={{ padding: "50px 0" }}>
                    No users
                  </td>
                </tr>
              )}
              {users.map((user, idx) => (
                <tr key={user.user_id}>
                  <td>{page.page * page.size + idx + 1}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email_id}</td>
                  <td className="center">{user.is_verified ? "Yes" : "No"}</td>
                  <td className="center">{user.is_admin ? "Admin" : ""}</td>
                  {/* <td className="center">{user.iban_number}</td> */}
                  <td className="center">
                    {moment(user.createdAt).format("MMMM DD, YYYY hh:mm")}
                  </td>
                  <td className="center">
                    <div className="action-buttons">
                      <Link to={`/dashboard/users/${user.user_id}`}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          style={{ marginRight: 5 }}
                        >
                          Edit
                        </Button>
                      </Link>
                      {/* <Button
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 5 }}
                        onClick={() => openTokensModal(user.user_id)}
                      >
                        Send Tokens
                      </Button> */}
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 5 }}
                        onClick={() => openDepositModal(user.user_id)}
                      >
                        Deposit
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ marginRight: 5 }}
                        onClick={() => openWithdrawModal(user.user_id)}
                      >
                        Withdraw
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => handleDelete(user.user_id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <TablePagination
                  rowsPerPageOptions={[10, 50]}
                  labelRowsPerPage="Items per page:"
                  page={page.page}
                  count={page.count}
                  rowsPerPage={page.size}
                  onRowsPerPageChange={(e) => {
                    setPage({ ...page, page: 0, size: e.target.value });
                    loadUsers({ ...page, page: 0, size: e.target.value });
                  }}
                  onPageChange={(e, idx) => {
                    setPage({ ...page, page: idx });
                    loadUsers({ ...page, page: idx });
                  }}
                />
              </tr>
            </tbody>
          </table>
        </CardContent>
      </Card>

      <DepositModal
        handleClose={closeDepositModal}
        open={deposit.modalStatus}
        depositAmount={deposit.amount}
        handleChange={handleChange}
        nextDepositModal={nextDepositModal}
      />
      <DepositInfoAdminModal
        handleClose={() => closeConfirmModal("deposit")}
        open={deposit.confirmModalStatus}
        depositAmount={deposit.amount}
        user={selectedUser}
        handleBack={previousDepositModal}
        handleFinish={handleDeposit}
      />

      <WithdrawModal
        handleClose={closeWithdrawModal}
        open={withdraw.modalStatus}
        withdrawAmount={withdraw.amount}
        handleChange={handleChange}
        nextWithdrawModal={nextWithdrawModal}
      />
      <WithdrawInfoAdminModal
        handleClose={() => closeConfirmModal("withdraw")}
        open={withdraw.confirmModalStatus}
        withdrawAmount={withdraw.amount}
        user={selectedUser}
        handleBack={previousWithdrawModal}
        handleFinish={handleWithdraw}
      />
    </div>
  );
};

function mapState(state) {
  const { alert } = state;
  const { deposit_res } = state.users;
  return { alert, deposit_res };
}
const actionCreators = {
  clearAlerts: alertActions.clear,
  successAlerts: alertActions.success,
  errorAlerts: alertActions.error,
  depositMoney: userActions.depositMoney,
  withdrawMoney: userActions.withdrawMoney,
};

const connectedPage = connect(mapState, actionCreators)(ManageUserPage);

export { connectedPage as ManageUserPage };
export default connectedPage;
