import React from "react";
import { Routes, Route } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";
import AdminDashboardPage from "../pages/AdminDashboard/DashboardPage";
import { ManageLocationPage } from "../pages/LocationPage/ManageLocationPage";
import { AddLocationPage } from "../pages/LocationPage/AddLocationPage";
import { EditLocationPage } from "../pages/LocationPage/EditLocationPage";
import { ManageUserPage } from "../pages/UserPage/ManageUserPage";
import { ManagePartnerPage } from "../pages/PartnerPage/ManagePartnerPage";
import { EditPartnerPage } from "../pages/PartnerPage/EditPartnerPage";
import { AddPartnerPage } from "../pages/PartnerPage/AddPartnerPage";
import { EditUserPage } from "../pages/UserPage/EditUserPage";
import { SearchPage } from "../pages/SearchPage";
import { PropertyDetailPage } from "../pages/PropertyDetailsPage";
import { ManagePropertyPage } from "../pages/PropertyPage/ManagePropertyPage";
import { AddPropertyPage } from "../pages/PropertyPage/AddPropertyPage";
import DashboardPage from "../pages/Dashboard/DashboardPage";
import InvestmentWalletPage from "../pages/Dashboard/InvestmentWalletPage";
import ProfilePage from "../pages/Dashboard/ProfilePage";
import PaymentsPage from "../pages/Dashboard/PaymentsPage";
import OrdersPage from "../pages/Dashboard/Orders";
import TransactionsPage from "../pages/Dashboard/TransactionsPage";
import { VerificationPage } from "../pages/VerificationKycPage";
import TokenWalletPage from "../pages/Dashboard/TokenWalletPage";
import { TermsOfUsePage } from "../pages/TermsOfUse";
import { FrequentlyAskedQuestions } from "../pages/FrequentlyAskedQuestions";
// import { UnderConstruction } from "../pages/UnderConstruction";

import AuthLayout from "./layouts/AuthLayout";
import ContactPage from "../pages/ContactPage";
import NewHomePage from "../pages/NewHomePage";

const AdminRoute = ({ logopath, pagebackground, logobackground }) => {
  return (
    <Routes>
      <Route
        path="/dashboard/admin"
        element={
          <AuthLayout>
            <AdminDashboardPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/partners"
        element={
          <AuthLayout adminPage={true}>
            <ManagePartnerPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/partners/edit/:id"
        element={
          <AuthLayout adminPage={true}>
            <EditPartnerPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/partners/add"
        element={
          <AuthLayout adminPage={true}>
            <AddPartnerPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/locations"
        element={
          <AuthLayout adminPage={true}>
            <ManageLocationPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/locations/add"
        element={
          <AuthLayout adminPage={true}>
            <AddLocationPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/locations/edit/:id"
        element={
          <AuthLayout adminPage={true}>
            <EditLocationPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/users"
        element={
          <AuthLayout adminPage={true}>
            <ManageUserPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/users/:id"
        element={
          <AuthLayout adminPage={true}>
            <EditUserPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/users/add"
        element={
          <AuthLayout adminPage={true}>
            <EditUserPage />
          </AuthLayout>
        }
      />
      <Route
        path="/users/kyc"
        element={
          <AuthLayout kycPage={true}>
            <>
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
                stickyPosition={200}
                noStickyClass="homeNoStickedHeader"
              />
              <VerificationPage />
            </>
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/orders"
        element={
          <AuthLayout>
            <OrdersPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/tokenwallet"
        element={
          <AuthLayout>
            <TokenWalletPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/payments"
        element={
          <AuthLayout>
            <PaymentsPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/transactions"
        element={
          <AuthLayout>
            <TransactionsPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties"
        element={
          <AuthLayout checkWallet={true}>
            <ManagePropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties/edit/:id"
        element={
          <AuthLayout>
            <AddPropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties/add"
        element={
          <AuthLayout>
            <AddPropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/wallet"
        element={
          <AuthLayout>
            <InvestmentWalletPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/profile"
        element={
          <AuthLayout>
            <ProfilePage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <AuthLayout>
            <DashboardPage />
          </AuthLayout>
        }
      />
      <Route
        path="/property/view/:id"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <PropertyDetailPage />
          </>
        }
      />
      <Route
        path="/marketplace"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <SearchPage />
          </>
        }
      />
      <Route
        path="/terms-of-use"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <TermsOfUsePage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/faq"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <FrequentlyAskedQuestions />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/contact-us"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <ContactPage />
            <FooterComponent />
          </>
        }
      />
      {/* <Route exact path="/" element={<UnderConstruction />} /> */}
      <Route
        exact
        path="/"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            {/* <HomePage background={pagebackground} /> */}
            <NewHomePage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="*"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            <NotFound />
            <FooterComponent />
          </>
        }
      />
    </Routes>
  );
};

export default AdminRoute;
export { AdminRoute };
