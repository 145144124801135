export const requiredKeyFigures = [
  "Bedrooms",
  "Bathrooms",
  "Sq Feet",
  "Parking Spaces",
];

export const initialState = {
  loaded: false,
  activeStep: 0,
  disabledButton: false,
  user: {},
  uploadImages: [],
  currentUpload: 0,
  property: {
    title: "",
    property_type: "direct",
    property_category_type: "apartment",
    token_name: "",
    address_line1: "",
    address_city: "",
    address_postcode: "",
    address_country: "",
    financial_instrument: 0,
    dividend_distribution: 0,
    macrolocation_heading_en: "",
    macrolocation_description_en: "",
    microlocation_heading_en: "",
    microlocation_description_en: "",
    macro_location_image: "",
    macro_location_image_preview: "",
    micro_location_image: "",
    micro_location_image_preview: "",
    key_figures: [
      {
        property_key_en: "Bedrooms",
        property_value_en: "",
        tab_value_key: 0,
        tab_value_val: 0,
      },
      {
        property_key_en: "Bathrooms",
        property_value_en: "",
        tab_value_key: 0,
        tab_value_val: 0,
      },
      {
        property_key_en: "Sq Feet",
        property_value_en: "",
        tab_value_key: 0,
        tab_value_val: 0,
      },
      {
        property_key_en: "Parking Spaces",
        property_value_en: "",
        tab_value_key: 0,
        tab_value_val: 0,
      },
    ],
    sections: [],
    partners: [],
    property_location: {
      lat: parseFloat(process.env.REACT_APP_MAP_LAT),
      lng: parseFloat(process.env.REACT_APP_MAP_LNG),
    },
    net_rental_income: "",
    management_costs: "",
    property_management_cost: "",
    rent_protection_cost: "",
    maintenance_cost: "",
    distribution_surplus: "",
    predicted_value_increase_en: "",
    predicted_value_increase_p_a: "",
    property_picture: [],
    property_documents: [],
    property_price: 0,
    financing_volume: "",
    soft_cap: "",
    total_share_count: "",
    end_date: new Date(),
    start_date: new Date(),
    promoted_date: "",
    property_floor_plans: [],
    legal_warnings_en: "",
    forecasted_distribution_en: "",
    upload_transaction_id: "",
    is_draft: true,
  },
  propertyPricing: {},
  uploadModalStatus: false,
  modalAction: "upload",
  predicted_value_increase_tabIndex: 0,
  legal_warnings_tabIndex: 0,
  forecasted_distribution_tabIndex: 0,
  available_partners_list: [],
  available_financial_instruments: [],
  available_dividend_distributions: [],
  available_locations: [],
  the_highlights_error: "",
  the_property_error: "",
  the_investment_error: "",
};
