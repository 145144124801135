import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Popper, Grow, InputAdornment } from "@material-ui/core";

const Filters = ({
  search,
  availableCountries,
  availableLocations,
  state,
  search_filter,
  key_figures_filter,
  handleChange,
  setFilter,
  toggleSearchFilter,
  toggleKeyFiguresFilter,
}) => {
  const getStyles = (city, cities) => {
    return {
      fontWeight: cities.indexOf(city) === -1 ? 500 : 800,
      backgroundColor: cities.indexOf(city) === -1 ? "" : "#c9c9c9",
    };
  };

  const handleDelete = (filter) => {
    let key = "",
      value = {};

    switch (filter) {
      case "search":
        key = "propertyValue";
        value = { min: 0, max: 0 };
        toggleSearchFilter();
        break;
      case "keyFigures":
        key = "keyFigures";
        Object.keys(state.search.keyFigures).forEach((keyFigure) => {
          value[keyFigure] = { min: 0, max: 0 };
        });
        toggleKeyFiguresFilter();
        break;
    }
    setFilter(value, key);
  };

  const scrollOnOpen = () => {
    const defaultHeight = 1083,
      defaultTop = 100;
    const windowHeight = window.innerHeight;
    const heightDiff = defaultHeight - windowHeight;

    let topDistance = defaultTop;
    if (heightDiff > 0) {
      topDistance += parseInt(heightDiff / 3);
    } else if (heightDiff < 0) {
      topDistance -= parseInt(-heightDiff / 3);
    }

    topDistance > window.scrollY &&
      window.scroll({
        top: topDistance,
        left: 0,
        behavior: "smooth",
      });
  };

  return (
    <>
      <FormControl className="form-control" style={{width: '100%'}}>
        <Select
          multiple
          inputProps={{
            name: "countries",
            id: "search-country",
          }}
          disableUnderline
          disabled={!availableCountries.length}
          value={search.countries}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <span>
                  {availableCountries.length
                    ? "Country"
                    : "Loading countries..."}
                </span>
              );
            }

            return selected.join(", ");
          }}
          MenuProps={{
            getContentAnchorEl: () => null,
            classes: { paper: "search-widget-dropdown-container" },
          }}
        >
          <MenuItem value="" disabled>
            {availableCountries.length ? "Country" : "Loading countries..."}
          </MenuItem>
          {availableCountries.length > 0 &&
            availableCountries.map((country, index) => (
              <MenuItem
                key={index}
                value={country}
                style={getStyles(country, search.countries)}
              >
                {country}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl className="form-control">
        <Select
          multiple
          disabled={!state.availableLocations.length > 0}
          inputProps={{
            name: "location",
            id: "search-location",
          }}
          disableUnderline
          value={search.location}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span>Location</span>;
            }

            return selected.join(", ");
          }}
          MenuProps={{
            getContentAnchorEl: () => null,
            classes: { paper: "search-widget-dropdown-container" },
          }}
        >
          <MenuItem value="" disabled>
            Location
          </MenuItem>
          {availableLocations.length > 0 &&
            availableLocations.map((location, index) => (
              <MenuItem
                key={index}
                value={`${location.locationName} (${location.locationCountry})`}
                style={getStyles(
                  `${location.locationName} (${location.locationCountry})`,
                  search.location
                )}
              >
                {`${location.locationName} (${location.locationCountry})`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <div className="form-control" style={{zIndex:5}}>
      <FormControl style={{width: '100%'}}>
        <Button
          variant="outlined"
          className="btn-filter"
          ref={search_filter}
          onClick={toggleSearchFilter}
        >
          {search.propertyValue.max !== 0
            ? `min: ${search.propertyValue.min}, max: ${search.propertyValue.max}`
            : "Price"}
        </Button>

        <Popper
          className="filter-box"
          open={state.searchFilterOpen}
          anchorEl={search_filter.current}
          role={undefined}
          transition
          disablePortal
          placement={"bottom-start"}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper className="filter-content">
                <p className="txt-title">Value</p>

                <div className="range-slider">
                  <div className="range-value">
                    <ValidatorForm
                      onSubmit={() => {}}
                      autoComplete="off"
                      instantValidate
                    >
                      <TextValidator
                        fullWidth
                        value={state.search.propertyValue.min}
                        onChange={(e) => {
                          const min =
                            parseInt(e.target.value) ||
                            state.propertyValueRange.min;
                          const max = state.search.propertyValue.max;
                          min >= 0 && setFilter({ min, max }, "propertyValue");
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        min={state.propertyValueRange.min}
                        max={state.search.propertyValue.max}
                        type="number"
                        label="Min"
                        validators={["required", "isNumber"]}
                        errorMessages={["This field is required", ""]}
                      />
                      <TextValidator
                        fullWidth
                        value={state.search.propertyValue.max}
                        onChange={(e) => {
                          const min = state.search.propertyValue.min;
                          const max = parseInt(e.target.value) || 0;
                          max >= 0 && setFilter({ min, max }, "propertyValue");
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        min={state.search.propertyValue.min}
                        max={state.search.propertyValue.max}
                        type="number"
                        label="Max"
                        validators={["required", "isNumber"]}
                      />
                    </ValidatorForm>
                  </div>
                </div>

                <div className="action-footer">
                  <Button
                    className="btn-delete"
                    onClick={() => handleDelete("search")}
                  >
                    Delete
                  </Button>
                  <Button className="btn-save" onClick={toggleSearchFilter}>
                    Save
                  </Button>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </FormControl>
      </div>

      <div className="form-control" style={{zIndex:5}}>
      <FormControl style={{width: '100%'}}>
        <Button
          variant="outlined"
          className="btn-filter"
          ref={key_figures_filter}
          onClick={toggleKeyFiguresFilter}
        >
          Key Figures
        </Button>

        <Popper
          className="filter-box"
          open={state.keyFiguresFilterOpen}
          anchorEl={key_figures_filter.current}
          role={undefined}
          transition
          disablePortal
          placement={"bottom-start"}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper className="filter-content">
                <p className="txt-title">Key Figures</p>

                <div
                  className="range-slider"
                  style={{ overflowY: "auto", height: "200px" }}
                >
                  <div className="range-value">
                    <Box sx={{ mb: 2 }}>
                      <Box>
                        <Typography
                          style={{
                            display: "block",
                            padding: 0,
                            marginBottom: "5px",
                          }}
                        >
                          Bedrooms
                        </Typography>
                      </Box>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          marginLeft: "2px",
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Bedrooms"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max = state.search.keyFigures["Bedrooms"].max;
                            min >= state.propertyValueRange.min &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bedrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Bedrooms"].max}
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Bedrooms"].max}
                          onChange={(e) => {
                            const min = state.search.keyFigures["Bedrooms"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bedrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Bedrooms"].min}
                          max={state.search.keyFigures["Bedrooms"].max}
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        style={{
                          display: "block",
                          padding: 0,
                          marginBottom: "5px",
                        }}
                      >
                        <span>Bathrooms</span>
                      </Typography>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Bathrooms"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max =
                              state.search.keyFigures["Bathrooms"].max;
                            min >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bathrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Bathrooms"].max}
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Bathrooms"].max}
                          onChange={(e) => {
                            const min =
                              state.search.keyFigures["Bathrooms"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bathrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Bathrooms"].min}
                          max={state.search.keyFigures["Bathrooms"].max}
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        style={{
                          display: "block",
                          padding: 0,
                          marginBottom: "5px",
                        }}
                      >
                        <span>Parking Spaces</span>
                      </Typography>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Parking Spaces"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max =
                              state.search.keyFigures["Parking Spaces"].max;
                            min >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Parking Spaces": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Parking Spaces"].max}
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Parking Spaces"].max}
                          onChange={(e) => {
                            const min =
                              state.search.keyFigures["Parking Spaces"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Parking Spaces": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Parking Spaces"].min}
                          max={state.search.keyFigures["Parking Spaces"].max}
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        style={{
                          display: "block",
                          padding: 0,
                          marginBottom: "5px",
                        }}
                      >
                        <span>Sq Feet</span>
                      </Typography>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Sq Feet"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max = state.search.keyFigures["Sq Feet"].max;
                            min >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Sq Feet": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Sq Feet"].max}
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Sq Feet"].max}
                          onChange={(e) => {
                            const min = state.search.keyFigures["Sq Feet"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Sq Feet": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Sq Feet"].min}
                          max={state.search.keyFigures["Sq Feet"].max}
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                  </div>
                </div>

                <div className="action-footer">
                  <Button
                    className="btn-delete"
                    onClick={() => handleDelete("keyFigures")}
                  >
                    Delete
                  </Button>
                  <Button className="btn-save" onClick={toggleKeyFiguresFilter}>
                    Save
                  </Button>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </FormControl>
      </div>

      <FormControl className="form-control" style={{width: '100%'}}>
        <Select
          multiple
          inputProps={{
            name: "categoryType",
            id: "search-category-type",
          }}
          disableUnderline
          value={search.categoryType}
          onChange={handleChange}
          // onOpen={scrollOnOpen}
          // MenuProps={{
          //   anchorOrigin: {
          //     vertical: "bottom",
          //     horizontal: "left",
          //   },
          //   getContentAnchorEl: null,
          // }}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span>Category Type of Property</span>;
            }
            return selected
              .map((type) => type[0].toUpperCase() + type.substring(1))
              .join(", ");
          }}
        >
          <MenuItem value="" disabled>
            Category Type of Property
          </MenuItem>
          <MenuItem value="apartment">Apartment</MenuItem>
          <MenuItem value="condo">Condo</MenuItem>
          <MenuItem value="penthouse">Penthouse</MenuItem>
          <MenuItem value="house">House</MenuItem>
          <MenuItem value="land">Land</MenuItem>
          <MenuItem value="hotel">Hotel</MenuItem>
          <MenuItem value="building">Building</MenuItem>
          <MenuItem value="villa">Villa</MenuItem>
          <MenuItem value="duplex">Duplex</MenuItem>
          <MenuItem value="office space">Office Space</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default Filters;
