import React, { Component } from "react";
import { Button, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import "react-input-range/lib/css/index.css";
import "./styles.scss";
import Filters from "./NewFilters";
import SearchIcon from "@material-ui/icons/Search";
import MobileFilters from "./MobileFilters";

class SearchSection extends Component {
  constructor(props) {
    super(props);

    this.search_filter = React.createRef();
    this.key_figures_filter = React.createRef();

    this.state = {
      availableCountries: [],
      availableLocations: [],
      searchFilterOpen: false,
      keyFiguresFilterOpen: false,
      propertyValueRange: { min: 0, max: 330000 },
      discoverWord: "property",
      dialogOpen: false,

      search: {
        location: [],
        countries: [],
        type: [],
        categoryType: [],
        status: "",
        keyFigures: {
          Bedrooms: {
            min: 0,
            max: 0,
          },
          Bathrooms: {
            min: 0,
            max: 0,
          },
          "Parking Spaces": {
            min: 0,
            max: 0,
          },
          "Sq Feet": {
            min: 0,
            max: 0,
          },
        },
        propertyValue: { min: 0, max: 0 },
        pricePerShareValue: { min: 0, max: 340 },
        dividendYieldValue: { min: 0, max: 4.2 },
        order: "",
        availability: false,
      },
      locations: [],
      properties: {},
    };
  }

  // Method to open the dialog
  handleOpen = () => {
    this.setState({ dialogOpen: true });
  };

  // Method to close the dialog
  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  componentDidMount() {
    const availableCountries = [];
    if (
      Object.keys(this.props.activePropertyLocations).length > 0 &&
      this.state.availableCountries.length === 0
    ) {
      Object.keys(this.props.activePropertyLocations).forEach((key) => {
        availableCountries.push(key);
      });
    }
    this.setState({
      ...this.state,
      availableCountries: availableCountries,
    });

    ValidatorForm.addValidationRule("belowThan", (value) => {
      return value < this.state.propertyValueRange.max;
    });
    ValidatorForm.addValidationRule("aboveThan", (value) => {
      return value > this.state.propertyValueRange.min;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(prevProps.activePropertyLocations).length !==
      Object.keys(this.props.activePropertyLocations).length
    ) {
      const availableCountries = [];
      if (
        Object.keys(this.props.activePropertyLocations).length > 0 &&
        this.state.availableCountries.length === 0
      ) {
        Object.keys(this.props.activePropertyLocations).forEach((key) => {
          availableCountries.push(key);
        });
      }
      this.setState({
        ...this.state,
        availableCountries: availableCountries,
      });
    }
    if (
      prevState.search.countries.length !== this.state.search.countries.length
    ) {
      const removedCountry =
        prevState.search.countries.length > this.state.search.countries.length;
      this.generateAvailableLocationsOptions(removedCountry);
    }
  }

  generateAvailableLocationsOptions(removedCountry) {
    const selectedCountries = [...this.state.search.countries];
    const availableLocations = [];
    if (selectedCountries.length > 0) {
      selectedCountries.forEach((country) =>
        this.props.activePropertyLocations[country].forEach(
          (countryLocation) => {
            availableLocations.push({
              locationName: countryLocation,
              locationCountry: country,
            });
          }
        )
      );
      if (removedCountry) {
        this.setState((prevState) => ({
          search: {
            ...prevState.search,
            location: [],
          },
        }));
      }
    }
    this.setState({ availableLocations });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("belowThan");
    ValidatorForm.removeValidationRule("aboveThan");
  }

  handleChange = (event) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [event.target.name]: event.target.value,
      },
    }));
  };

  setFilter = (value, type) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value,
      },
    }));
  };

  toggleSearchFilter = () => {
    this.setState({ searchFilterOpen: !this.state.searchFilterOpen });
  };

  toggleKeyFiguresFilter = () => {
    this.setState({ keyFiguresFilterOpen: !this.state.keyFiguresFilterOpen });
  };

  formatePrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  getSearchQueryParams = () => {
    const { search } = this.state;
    const params = new URLSearchParams();

    if (search["location"].length) {
      params.append("location", JSON.stringify(search["location"]));
    }
    if (search["countries"].length) {
      params.append("countries", JSON.stringify(search["countries"]));
    }
    if (search["type"].length) {
      params.append("type", JSON.stringify(search["type"]));
    }
    if (search["categoryType"].length) {
      params.append("categoryType", JSON.stringify(search["categoryType"]));
    }
    if (search["status"]) {
      params.append("status", search["status"]);
    }
    if (search["propertyValue"]["min"] || search["propertyValue"]["max"]) {
      params.append("propertyValue", JSON.stringify(search["propertyValue"]));
    }

    const keyFigures = {};
    for (const key in search["keyFigures"]) {
      if (
        search["keyFigures"][key]["min"] ||
        search["keyFigures"][key]["max"]
      ) {
        keyFigures[key] = search["keyFigures"][key];
      }
    }

    if (Object.keys(keyFigures).length) {
      params.append("keyFigures", JSON.stringify(keyFigures));
    }

    return params.toString();
  };

  render() {
    const { search, availableCountries, availableLocations } = this.state;
    return (
      <div className="search-hero-section">
        {/* <h2 className="search-title">
          <div>
            Discover your dream {this.state.discoverWord.split('').map((letter, index) => {
              return <span key={index}>{letter}</span>
            })}
          </div>
          <small>Platform powered by blockchain technology</small>
        </h2> */}
        <div className="search-form">
          <Button
            variant="outlined"
            className="btn-filter mobile-filters-btn"
            onClick={this.handleOpen}
          >
            Filter Properties...
          </Button>
          <Filters
            search={search}
            availableCountries={availableCountries}
            availableLocations={availableLocations}
            state={this.state}
            setState={this.setState}
            search_filter={this.search_filter}
            key_figures_filter={this.key_figures_filter}
            handleChange={this.handleChange}
            setFilter={this.setFilter}
            toggleSearchFilter={this.toggleSearchFilter}
            toggleKeyFiguresFilter={this.toggleKeyFiguresFilter}
          />
          <div className="search-btn-wrapper">
            <Link
              to={`/marketplace?${this.getSearchQueryParams()}`}
              state={{
                searchInvestment: this.state.search,
                activePropertyLocations: this.props.activePropertyLocations,
              }}
            >
              <IconButton className="search-btn">
                <SearchIcon style={{ color: "#fff" }} />
              </IconButton>
            </Link>
          </div>
        </div>
        <MobileFilters
          open={this.state.dialogOpen}
          handleClose={this.handleClose}
          search={search}
          availableCountries={availableCountries}
          availableLocations={availableLocations}
          state={this.state}
          setState={this.setState}
          search_filter={this.search_filter}
          key_figures_filter={this.key_figures_filter}
          handleChange={this.handleChange}
          setFilter={this.setFilter}
          toggleSearchFilter={this.toggleSearchFilter}
          toggleKeyFiguresFilter={this.toggleKeyFiguresFilter}
          activePropertyLocations={this.props.activePropertyLocations}
          getSearchQueryParams={this.getSearchQueryParams}
        />
      </div>
    );
  }
}

export default SearchSection;
