import React from "react";

const PartnersSection = ({ property }) => {
  const partners = property.property_project_partners;
  // console.log(partners);
  if (partners) {
    return (
      <div className="partners-section">
        <div className="partners-wrapper">
          <h3>Partners</h3>
          <div className="partners-list">
            {partners.map((partner) => (
              <div key={partner.id} className="partner">
                <img
                  height="60px"
                  src={partner.partner_master.logo}
                  style={{ marginBottom: "16px" }}
                />
                <h4>{partner.partner_master.name}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: partner.partner_master.description,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default PartnersSection;
