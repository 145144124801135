import React from "react";
import { findSectionByKey } from "../../components/helpers/helpers";

const AboutSection = ({ property }) => {
  const propertySection = findSectionByKey(
    property.property_sections,
    "the_property"
  );
  const highlightsSection = findSectionByKey(
    property.property_sections,
    "the_highlights"
  );

  if (propertySection || highlightsSection) {
    return (
      <div className="about-section">
        <div className="about-wrapper">
          {propertySection ? (
            <>
              <h3>{propertySection.heading}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: propertySection.description,
                }}
              />
            </>
          ) : null}
          {highlightsSection ? (
            <>
              <h3>{highlightsSection.heading}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: highlightsSection.description,
                }}
              />
            </>
          ) : null}
        </div>
      </div>
    );
  }

  return null;
};

export default AboutSection;
