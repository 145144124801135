import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobileFilters = ({
  search,
  availableCountries,
  availableLocations,
  state,
  search_filter,
  key_figures_filter,
  handleChange,
  setFilter,
  toggleSearchFilter,
  toggleKeyFiguresFilter,
  activePropertyLocations,
  open,
  handleClose,
  getSearchQueryParams,
}) => {
  const getStyles = (city, cities) => {
    return {
      fontWeight: cities.indexOf(city) === -1 ? 500 : 800,
      backgroundColor: cities.indexOf(city) === -1 ? "" : "#c9c9c9",
    };
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Filter Properties"}
        </DialogTitle>
        <DialogContent className="mobile-filters">
          <FormControl
            className="form-control"
            style={{ width: "100%", marginBottom: "8px" }}
          >
            <Select
              multiple
              className="custom-select"
              inputProps={{
                name: "countries",
                id: "search-country",
              }}
              variant="outlined"
              disableUnderline
              disabled={!availableCountries.length}
              value={search.countries}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <span>
                      {availableCountries.length
                        ? "Country"
                        : "Loading countries..."}
                    </span>
                  );
                }

                return selected.join(", ");
              }}
              MenuProps={{
                getContentAnchorEl: () => null,
                classes: { paper: "search-widget-dropdown-container" },
              }}
            >
              <MenuItem value="" disabled>
                {availableCountries.length ? "Country" : "Loading countries..."}
              </MenuItem>
              {availableCountries.length > 0 &&
                availableCountries.map((country, index) => (
                  <MenuItem
                    key={index}
                    value={country}
                    style={getStyles(country, search.countries)}
                  >
                    {country}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl className="form-control" style={{ width: "100%" }}>
            <Select
              className="custom-select"
              multiple
              disabled={!state.availableLocations.length > 0}
              inputProps={{
                name: "location",
                id: "search-location",
              }}
              fullWidth
              variant="outlined"
              disableUnderline
              value={search.location}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span>Location</span>;
                }

                return selected.join(", ");
              }}
              MenuProps={{
                getContentAnchorEl: () => null,
                classes: {
                  paper:
                    "search-widget-dropdown-container custom-widget-container",
                },
              }}
            >
              <MenuItem value="" disabled>
                Location
              </MenuItem>
              {availableLocations.length > 0 &&
                availableLocations.map((location, index) => (
                  <MenuItem
                    key={index}
                    value={`${location.locationName} (${location.locationCountry})`}
                    style={getStyles(
                      `${location.locationName} (${location.locationCountry})`,
                      search.location
                    )}
                  >
                    {`${location.locationName} (${location.locationCountry})`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <div className="form-control" style={{ zIndex: 5 }}>
            <FormControl style={{ width: "100%" }}>
              <p className="filter-title">Price range</p>

              <div className="range-slider">
                <div className="range-value">
                  <ValidatorForm
                    onSubmit={() => {}}
                    autoComplete="off"
                    instantValidate
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <TextValidator
                      fullWidth
                      value={state.search.propertyValue.min}
                      onChange={(e) => {
                        const min =
                          parseInt(e.target.value) ||
                          state.propertyValueRange.min;
                        const max = state.search.propertyValue.max;
                        min >= 0 && setFilter({ min, max }, "propertyValue");
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      min={state.propertyValueRange.min}
                      max={state.search.propertyValue.max}
                      variant="outlined"
                      size="small"
                      type="number"
                      label="Min"
                      validators={["required", "isNumber"]}
                      errorMessages={["This field is required", ""]}
                    />
                    <TextValidator
                      fullWidth
                      value={state.search.propertyValue.max}
                      onChange={(e) => {
                        const min = state.search.propertyValue.min;
                        const max = parseInt(e.target.value) || 0;
                        max >= 0 && setFilter({ min, max }, "propertyValue");
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      min={state.search.propertyValue.min}
                      max={state.search.propertyValue.max}
                      variant="outlined"
                      size="small"
                      type="number"
                      label="Max"
                      validators={["required", "isNumber"]}
                    />
                  </ValidatorForm>
                </div>
              </div>
            </FormControl>
          </div>

          <div className="form-control" style={{ zIndex: 5 }}>
            <FormControl style={{ width: "100%" }}>
              <p className="filter-title">Key Figures</p>

              <div className="range-slider">
                <div className="range-value">
                  <Box className="key-figure-filter">
                    <Box sx={{ mb: 2 }}>
                      <Box>
                        <Typography
                          style={{
                            display: "block",
                            padding: 0,
                            marginBottom: "5px",
                          }}
                        >
                          Bedrooms
                        </Typography>
                      </Box>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          marginLeft: "2px",
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Bedrooms"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max = state.search.keyFigures["Bedrooms"].max;
                            min >= state.propertyValueRange.min &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bedrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Bedrooms"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Bedrooms"].max}
                          onChange={(e) => {
                            const min = state.search.keyFigures["Bedrooms"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bedrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Bedrooms"].min}
                          max={state.search.keyFigures["Bedrooms"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                  </Box>
                  <Box className="key-figure-filter">
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        style={{
                          display: "block",
                          padding: 0,
                          marginBottom: "5px",
                        }}
                      >
                        <span>Bathrooms</span>
                      </Typography>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Bathrooms"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max =
                              state.search.keyFigures["Bathrooms"].max;
                            min >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bathrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Bathrooms"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Bathrooms"].max}
                          onChange={(e) => {
                            const min =
                              state.search.keyFigures["Bathrooms"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  Bathrooms: { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Bathrooms"].min}
                          max={state.search.keyFigures["Bathrooms"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                  </Box>
                  <Box className="key-figure-filter">
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        style={{
                          display: "block",
                          padding: 0,
                          marginBottom: "5px",
                        }}
                      >
                        <span>Parking Spaces</span>
                      </Typography>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Parking Spaces"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max =
                              state.search.keyFigures["Parking Spaces"].max;
                            min >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Parking Spaces": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Parking Spaces"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Parking Spaces"].max}
                          onChange={(e) => {
                            const min =
                              state.search.keyFigures["Parking Spaces"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Parking Spaces": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Parking Spaces"].min}
                          max={state.search.keyFigures["Parking Spaces"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                  </Box>
                  <Box className="key-figure-filter">
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        style={{
                          display: "block",
                          padding: 0,
                          marginBottom: "5px",
                        }}
                      >
                        <span>Sq Feet</span>
                      </Typography>
                      <ValidatorForm
                        onSubmit={() => {}}
                        autoComplete="off"
                        instantValidate
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <TextValidator
                          value={state.search.keyFigures["Sq Feet"].min}
                          onChange={(e) => {
                            const min = parseInt(e.target.value) || 0;
                            const max = state.search.keyFigures["Sq Feet"].max;
                            min >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Sq Feet": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={0}
                          max={state.search.keyFigures["Sq Feet"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Min"
                          validators={["required", "isNumber"]}
                          errorMessages={["This field is required", ""]}
                        />
                        <TextValidator
                          value={state.search.keyFigures["Sq Feet"].max}
                          onChange={(e) => {
                            const min = state.search.keyFigures["Sq Feet"].min;
                            const max = parseInt(e.target.value) || 0;
                            max >= 0 &&
                              setFilter(
                                {
                                  ...state.search.keyFigures,
                                  "Sq Feet": { min, max },
                                },
                                "keyFigures"
                              );
                          }}
                          min={state.search.keyFigures["Sq Feet"].min}
                          max={state.search.keyFigures["Sq Feet"].max}
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Max"
                          validators={["required", "isNumber"]}
                        />
                      </ValidatorForm>
                    </Box>
                  </Box>
                </div>
              </div>
            </FormControl>
          </div>

          <FormControl className="form-control" style={{ width: "100%" }}>
            <Select
              className="custom-select"
              multiple
              inputProps={{
                name: "categoryType",
                id: "search-category-type",
              }}
              disableUnderline
              value={search.categoryType}
              onChange={handleChange}
              variant="outlined"
              // onOpen={scrollOnOpen}
              // MenuProps={{
              //   anchorOrigin: {
              //     vertical: "bottom",
              //     horizontal: "left",
              //   },
              //   getContentAnchorEl: null,
              // }}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span>Category</span>;
                }
                return selected
                  .map((type) => type[0].toUpperCase() + type.substring(1))
                  .join(", ");
              }}
            >
              <MenuItem value="" disabled>
                Category
              </MenuItem>
              <MenuItem value="apartment">Apartment</MenuItem>
              <MenuItem value="condo">Condo</MenuItem>
              <MenuItem value="penthouse">Penthouse</MenuItem>
              <MenuItem value="house">House</MenuItem>
              <MenuItem value="land">Land</MenuItem>
              <MenuItem value="hotel">Hotel</MenuItem>
              <MenuItem value="building">Building</MenuItem>
              <MenuItem value="villa">Villa</MenuItem>
              <MenuItem value="duplex">Duplex</MenuItem>
              <MenuItem value="office space">Office Space</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "16px",
            paddingRight: "16px",
            height: "60px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            className="reset-btn"
          >
            Reset
          </Button>
          <Link
            to={`/marketplace?${getSearchQueryParams()}`}
            state={{
              searchInvestment: search,
              activePropertyLocations: activePropertyLocations,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              className="show-btn"
            >
              Show results
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileFilters;
